export const cvDataEnglish = [
    {
      "sessionName": "personalInformation",
      "headerName": "Personal Information",
      "firstName": "Yan Pei",
      "lastName": "Li",
      "nickName": "Hiko",
      "email": "liyanpei2004@outlook.com",
      "phoneNumber": "852 6204 0827",
      "personalWebsite": "hiko.dev",
      "address": "Hong Hum, Kowloon, Hong Kong",
      "introduction": "A driven and self-educated Full-Stack Software Engineer committed to delivering innovative technological solutions in computer science. Demonstrates extensive expertise in TypeScript, React, Next.js, NestJS, and Python FastAPI."
    },
    {
      "sessionName": "education",
      "headerName": "EDUCATION EXPERIENCE",
      "educationExperience": [
        {
          "schoolName": "UOW College HongKong",
          "schoolLocation": "Hong Kong",
          "degree": "Associate of Engineering",
          "major": "Engineering",
          "startDate": "2022-09-01",
          "endDate": "2024-06-30",
          "gpa": "3.3 / 4.3"
        },
        {
          "schoolName": "Hong Kong Metropolitan University",
          "schoolLocation": "Hong Kong",
          "degree": "Bachelor of Science with Honours in Computer Engineering",
          "major": "Computer Engineering",
          "startDate": "2024-09-01",
          "endDate": "2026-06-30",
          "gpa": "3.15 / 4.0"
        }
      ]
    },
    {
      "sessionName": "skill",
      "headerName": "PROGRAMMING LANGUAGES & TECHNICAL SKILLS",
      "languages": [
        {
          "language": "Python",
          "level": "Highly proficient in Python, specializing in data cleaning, web scraping, and backend development."
        },
        {
          "language": "C++",
          "level": "Fundamental proficiency in C++ at an academic level."
        },
        {
          "language": "Java",
          "level": "Basic competency in Java acquired through academic studies."
        },
        {
          "language": "Typescript",
          "level": "Exhibited robust expertise in full-stack development using TypeScript across diverse projects."
        },
        {
          "language": "React",
          "level": "Proven expertise in React as part of comprehensive full-stack development projects."
        },
        {
          "language": "Next.js",
          "level": "Strong proficiency in Next.js demonstrated through multiple full-stack implementations."
        },
        {
          "language": "Git",
          "level": "Daily utilization of Git for version control and effective collaborative development."
        },
        {
          "language": "NoSQL",
          "level": "Adept in integrating and managing NoSQL databases such as MongoDB using Python."
        },
        {
          "language": "HTML/CSS",
          "level": "Experienced in developing responsive and visually appealing UI components in React using HTML/CSS."
        }
      ],
      "technical": [
        {
          "name": "networking",
          "description": [
            "Implemented Basic Authentication protocols (RFC 7617) with robust security measures.",
            "Implemented Bearer Authentication (RFC 6750) using JWT (RFC 7519) for secure access control.",
            "Proficient in encapsulating and managing HTTP request headers.",
            "Extensive experience in generating comprehensive API documentation utilizing OpenAPI and Swagger."
          ]
        },
        {
          "name": "Infrastructure & OS",
          "description": [
            "Skilled in managing and optimizing Cloudflare DNS configurations.",
            "Demonstrated experience in managing both Ubuntu server and desktop environments.",
            "Expert in configuring NGINX servers within Docker containers to support web development workflows.",
            "Proficient in Docker, encompassing network configuration and custom shell scripting for effective container management.",
            "Competent in the setup and management of secure SSH servers."
          ]
        }
      ]
    },
    {
      "sessionName": "project",
      "headerName": "PROJECTS EXPERIENCE",
      "projectExperience": [
        {
          "title": "WeGreen AI (wegreen.ltd)",
          "startDate": "2024-10",
          "endDate": "2026-09",
          "projectLocation": "Hong Kong",
          "description": "Focused on enhancing waste management efficiency by minimizing classification time. (planed to be finished in 2026)",
          "features": [
            {
              "description": "Deep Learning Model for Waste Classification",
              "furtherExplanation": [
                "Developed an advanced deep learning model using TensorFlow and PyTorch to optimize waste classification efficiency."
              ]
            },
            {
              "description": "Machine Vision Recognition",
              "furtherExplanation": [
                "Integrated machine vision recognition technology to enhance classification accuracy and operational speed."
              ]
            },
            {
              "description": "IoT Technology",
              "furtherExplanation": [
                "Utilized IoT technology to enable real-time data transmission and intelligent control of devices."
              ]
            }
          ]
        },
        {
          "title": "Project EASILY-CV: CV Generator",
          "startDate": "2023-08",
          "endDate": "2023-10",
          "projectLocation": "Hong Kong",
          "description": "Developed an online CV generator as an open-source project, employing MongoDB Atlas for data storage, NextJS as the full-stack framework, and deployed via Docker on Linode for hosting.",
          "features": [
            {
              "description": "Deep understanding the state management in React for real-time data rendering",
              "furtherExplanation": [
                "Demonstrated in-depth expertise in React state management to facilitate real-time data rendering, allowing users to observe immediate CV updates based on their inputs."
              ]
            },
            {
              "description": "Better understanding of interface and type in Typescript",
              "furtherExplanation": [
                "Enhanced understanding of interfaces and type systems in TypeScript to ensure robust application architecture."
              ]
            },
            {
              "description": "Learnt CSS print and playwright for pdf exporting",
              "furtherExplanation": [
                "Gained proficiency in CSS print and Playwright to implement seamless PDF export functionality."
              ]
            }
          ]
        },
        {
          "title": "Project ASA: Cloud-based robot delivery management system",
          "startDate": "2023-07",
          "endDate": "2023-08",
          "projectLocation": "Hong Kong",
          "description": "A client-driven project, this web application (developed using TypeScript) enables users to design and manage delivery routes for both robotic and human couriers.",
          "features": [
            {
              "description": "Learned how to update objects in react state (list operation and restructure)",
              "furtherExplanation": [
                "Acquired proficiency in updating React state objects—including list operations, restructuring, adding/removing items, resetting changes, and updating deeply nested array values based on previous state and user inputs."
              ]
            },
            {
              "description": "Reorder items in a list and slice list to different sub-list by using index",
              "furtherExplanation": [
                "Effectively reordered list items and partitioned arrays into sub-lists using indexing techniques."
              ]
            },
            {
              "description": "Learnt knowledge of JWT authentication, server-side rendering, docker containerization and NGINX networking",
              "furtherExplanation": [
                "Gained practical knowledge of JWT authentication, server-side rendering, Docker containerization, and NGINX networking."
              ]
            },
            {
              "description": "Understood and used JWT for user authentication",
              "furtherExplanation": [
                "Implemented JWT to establish secure user authentication protocols."
              ]
            }
          ]
        },
        {
          "title": "Project RAKU: AI assistant job search system",
          "startDate": "2023-03",
          "endDate": "2023-11",
          "projectLocation": "Hong Kong",
          "description": "Developed an AI-powered job query chatbot integrating OpenAI's API, enabling users to conduct job searches using natural language queries.",
          "features": [
            {
              "description": "Developed website scraping capabilities to scrape and extract data from various famous online employment companies in Hong Kong",
              "furtherExplanation": [
                "Engineered web scraping functionality to extract and consolidate data from leading online employment platforms in Hong Kong."
              ]
            },
            {
              "description": "Used Python (FastAPI) as the backend programming language for the project framework",
              "furtherExplanation": [
                "Utilized Python (FastAPI) to construct a robust backend framework for the application."
              ]
            },
            {
              "description": "Leveraged the experience from Project Gaia to design a solid data structure and used MongoDB for building the database",
              "furtherExplanation": [
                "Applied insights from Project GAIA to design a resilient data structure and implemented MongoDB for effective database management."
              ]
            }
          ]
        },
        {
          "title": "Project GAIA: Design data structure",
          "startDate": "2022-11",
          "endDate": "2023-11",
          "projectLocation": "Hong Kong",
          "description": "Focused on designing an optimized NoSQL data structure for efficient storage of geostatic data.",
          "features": [
            {
              "description": "Designed database using MongoDB to store non-structural geostatic data for efficient data storage and retrieval",
              "furtherExplanation": [
                "Engineered a MongoDB-based database solution to store and retrieve non-structural geostatic data with optimal efficiency."
              ]
            },
            {
              "description": "Learnt the use of hashing for data content comparison and history management",
              "furtherExplanation": [
                "Gained proficiency in applying hashing techniques for accurate data comparison and historical data management."
              ]
            },
            {
              "description": "Learnt how to reformat and restructure raw data (dictionary/JSON) by using python",
              "furtherExplanation": [
                "Mastered techniques for reformatting and restructuring raw data (dictionary/JSON) using Python."
              ]
            }
          ]
        }
      ]
    },
    {
      "sessionName": "workExperience",
      "headerName": "WORK / INTERN EXPERIENCE",
      "experiences": [
        {
          "companyName": "WeGreen AI",
          "companyURL": "wegreen.ltd",
          "jobTitle": "COT",
          "jobDescription": "Driving innovation in waste management with AI-powered solutions, enhancing recycling processes to be smarter, faster, and more efficient for a sustainable future.",
          "features": [
            {
              "description": "Secured $130K in funding from HKBU and HKSTP to advance product development and support sustainable growth.",
              "furtherExplanation": [
               
              ]
            },
            {
              "description": "Led the development of our smart bin technology, optimizing waste management solutions.",
              "furtherExplanation": [
                
              ]
            },
            {
              "description": "Fostered a collaborative culture that promotes innovation and empowers teams to excel.",
              "furtherExplanation": [
                ]
            }
          ],
          "location": "Hong Kong",
          "startDate": "2024-11",
          "endDate": "now",
          "relatedSkills": [
            "Product Development",
            "Microcontrollers",
            "Technical Product Development",
            "Networking",
            "Machine Learning",
            "Computer Version"
          ]
        },
        {
          "companyName": "Dongguan Honsenn Electronice Technology Co., Ltd.",
          "companyURL": "honsennaudio.com/",
          "jobTitle": "Tech Researcher",
          "jobDescription": "Contributed to the development of microcontroller functionalities and provided robust support for product development initiatives.",
          "features": [],
          "location": "Dongguan",
          "startDate": "2024-07",
          "endDate": "2024-09",
          "relatedSkills": [
            "Product Development",
            "Microcontrollers",
            "Technical Product Development"
          ]
        },
        {
          "companyName": "GoSmart Everywhere Limited",
          "companyURL": "gosmart-everywhere-limited.webflow.io",
          "jobTitle": "Web Development Intern",
          "jobDescription": "Collaborated closely with experienced engineers, contributing to project development and honing advanced software engineering skills.",
          "features": [],
          "location": "Hong Kong",
          "startDate": "2023-08",
          "endDate": "2024-09",
          "relatedSkills": [
            "TypeScript",
            "React.js",
            "Python",
            "Networking",
            "Networking Building",
            "Web Development",
            "Engineering"
          ]
        }
      ]
    },
    {
      "sessionName": "extraSkill",
      "headerName": "LANGUAGES AND SKILLS",
      "points": [
        "Native Mandarin speaker",
        "Basic proficiency in Cantonese for listening and speaking",
        "Fluent in English for both listening and speaking"
      ]
    },
    {
      "sessionName": "certification",
      "headerName": "CERTIFICATIONS",
      "certifications": [
        {
          "issuingOrganization": "NVIDIA",
          "organizationURL": "learn.nvidia.com",
          "CertificationList": [
            {
              "certificationName": "Getting Started with AI on Jetson Nano",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "fQ-LE71ATl6WD5MN4yhwtQ",
              "credentialURL": "https://learn.nvidia.com/certificates?id=fQ-LE71ATl6WD5MN4yhwtQ"
            },
            {
              "certificationName": "Learn OpenUSD: Learning About Stages, Prims and Attributes",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "y__S8YX7QzONWbqN12vU-g",
              "credentialURL": "https://learn.nvidia.com/certificates?id=y__S8YX7QzONWbqN12vU-g"
            },
            {
              "certificationName": "Learn OpenUSD: Traversing Stages",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "IeQUvQF6S-iNMTQCCPePOA",
              "credentialURL": "https://learn.nvidia.com/certificates?id=IeQUvQF6S-iNMTQCCPePOA"
            },
            {
              "certificationName": "Learn OpenUSD: Understanding Model Kinds",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "La6VqkPhSjejniAsjJSJAg",
              "credentialURL": "https://learn.nvidia.com/certificates?id=La6VqkPhSjejniAsjJSJAg"
            },
            {
              "certificationName": "Learn OpenUSD: Using Attributes",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "p7pwyGVYTUq7KiXa11NcRw",
              "credentialURL": "https://learn.nvidia.com/certificates?id=p7pwyGVYTUq7KiXa11NcRw"
            },
            {
              "certificationName": "使用 Jetson Nano 开发 AI 应用",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "iZTC_AWGSUG7D_Kfg13-Fg",
              "credentialURL": "https://learn.nvidia.com/certificates?id=iZTC_AWGSUG7D_Kfg13-Fg"
            },
            {
              "certificationName": "基于 Jetson Nano 构建视频 AI 的端侧应用",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "Poy-Xqz_QzudkNj5YwFJqA",
              "credentialURL": "https://learn.nvidia.com/certificates?id=Poy-Xqz_QzudkNj5YwFJqA"
            }
          ]
        },
        {
          "issuingOrganization": "Cisco",
          "organizationURL": "netacad.com",
          "CertificationList": [
            {
              "certificationName": "Python Essentials", 
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "4f18dd7a-7133-48b2-8093-f3065cecaad0",
              "credentialURL": "https://www.credly.com/badges/4f18dd7a-7133-48b2-8093-f3065cecaad0/public_url"
            }
          ]
        }
      ]
    }
  ];
  export const cvDataChinese = [
    {
      "sessionName": "personalInformation",
      "headerName": "个人信息",
      "firstName": "彦霈",
      "lastName": "李",
      "nickName": "Hiko",
      "email": "liyanpei2004@outlook.com",
      "phoneNumber": "852 6204 0827",
      "personalWebsite": "hiko.dev",
      "address": "香港九龙红磡",
      "introduction": "一位充满热情且自我驱动的全栈软件工程师，致力于在计算机科学领域提供创新性的技术解决方案，熟练掌握 TypeScript、React、Next.js、NestJS 和 Python FastAPI "
    },
    {
      "sessionName": "education",
      "headerName": "教育经历",
      "educationExperience": [
        {
          "schoolName": "香港伍伦贡学院",
          "schoolLocation": "香港",
          "degree": "工程副学士",
          "major": "工程",
          "startDate": "2022-09-01",
          "endDate": "2024-06-30",
          "gpa": "3.3 / 4.3"
        },
        {
          "schoolName": "香港都会大学",
          "schoolLocation": "香港",
          "degree": "计算机工程荣誉理学士",
          "major": "计算机工程",
          "startDate": "2024-09-01",
          "endDate": "2026-06-30",
          "gpa": "3.15 / 4.0"
        }
      ]
    },
    {
      "sessionName": "skill",
      "headerName": "编程语言和技术技能",
      "languages": [
        {
          "language": "Python",
          "level": "在数据清理、网页抓取及后端开发方面表现出高度熟练度 "
        },
        {
          "language": "C++",
          "level": "具备基本的 C++ 编程能力（学术水平） "
        },
        {
          "language": "Java",
          "level": "具备基本的 Java 编程能力（学术水平） "
        },
        {
          "language": "Typescript",
          "level": "在多个项目中展现出全栈开发的专业能力，尤其擅长使用 TypeScript "
        },
        {
          "language": "React",
          "level": "在 React 全栈开发项目中表现出专业技能 "
        },
        {
          "language": "Git",
          "level": "每日使用 Git 进行版本控制及团队协作 "
        },
        {
          "language": "NoSQL",
          "level": "熟练使用 Python 集成和管理 NoSQL 数据库（如 MongoDB） "
        },
        {
          "language": "HTML/CSS",
          "level": "擅长在 React 中开发响应式且具视觉吸引力的 UI 组件，精通 HTML/CSS "
        }
      ],
      "technical": [
        {
          "name": "网络",
          "description": [
            "实施基本认证协议（RFC 7617），确保数据传输安全 ",
            "采用基于 JWT（RFC 7519）的 Bearer 认证（RFC 6750）机制，保障访问控制 ",
            "熟练封装和管理 HTTP 请求头 ",
            "拥有丰富的 OpenAPI 与 Swagger API 文档编写经验 "
          ]
        },
        {
          "name": "基础设施与操作系统",
          "description": [
            "熟悉 Cloudflare DNS 的配置与优化 ",
            "在 Ubuntu 服务器及桌面环境中具有丰富的管理经验 ",
            "通过 Docker 部署与配置 NGINX 服务器以支持网站开发 ",
            "精通 Docker，通过自定义 shell 脚本实现网络配置及容器管理 ",
            "具备 SSH 服务器设置与管理的专业能力 "
          ]
        }
      ]
    },
    {
      "sessionName": "project",
      "headerName": "项目经验",
      "projectExperience": [
        {
          "title": "WeGreen AI (wegreen.ltd)",
          "startDate": "2024-10",
          "endDate": "2026-09",
          "projectLocation": "香港",
          "description": "致力于通过缩短分类时间来提升废物管理效率 （计划于 2026 年完成）",
          "features": [
            {
              "description": "废物分类的深度学习模型",
              "furtherExplanation": [
                "开发基于深度学习的废物分类模型，利用 TensorFlow 与 PyTorch 实现高效分类 "
              ]
            },
            {
              "description": "机器视觉识别",
              "furtherExplanation": [
                "整合机器视觉识别技术以提高分类准确性和处理速度 "
              ]
            },
            {
              "description": "物联网技术",
              "furtherExplanation": [
                "借助物联网技术实现实时数据传输和智能设备控制 "
              ]
            }
          ]
        },
        {
          "title": "项目 EASILY-CV：简历生成器",
          "startDate": "2023-08",
          "endDate": "2023-10",
          "projectLocation": "香港",
          "description": "开发了一个在线简历生成器作为开源项目，采用 MongoDB Atlas 作为数据存储，NextJS 作为全栈框架，并通过 Docker 在 Linode 平台上部署 ",
          "features": [
            {
              "description": "深入理解 React 中的状态管理以实现实时数据渲染",
              "furtherExplanation": [
                "深入掌握 React 状态管理，实现实时数据渲染，使用户可立即看到简历更新 "
              ]
            },
            {
              "description": "更好地理解 Typescript 中的接口和类型",
              "furtherExplanation": [
                "提升了对 TypeScript 接口及类型系统的理解，确保应用架构的稳健性 "
              ]
            },
            {
              "description": "学习了 CSS 打印和 playwright 用于 PDF 导出",
              "furtherExplanation": [
                "掌握 CSS 打印及 Playwright 技术，实现无缝的 PDF 导出功能 "
              ]
            }
          ]
        },
        {
          "title": "项目 ASA：基于云的机器人配送管理系统",
          "startDate": "2023-07",
          "endDate": "2023-08",
          "projectLocation": "香港",
          "description": "这是一个客户定制项目，基于 TypeScript 的 Web 应用程序，允许用户设计并管理机器人或人工配送的路线，以高效运送文件或货物 ",
          "features": [
            {
              "description": "学习如何在 React 状态中更新对象（列表操作和重构）",
              "furtherExplanation": [
                "掌握在 React 状态中更新对象的技巧，包括列表操作、重构、添加/删除项目、重置用户更改及更新深层嵌套数组值 "
              ]
            },
            {
              "description": "通过使用索引重新排序列表中的项目并将列表切片为不同的子列表",
              "furtherExplanation": [
                "有效利用索引重新排序列表，并将其分割成多个子列表 "
              ]
            },
            {
              "description": "学习了JWT认证、服务器端渲染、Docker容器化和NGINX网络的知识",
              "furtherExplanation": [
                "获得了 JWT 认证、服务器端渲染、Docker 容器化及 NGINX 网络配置方面的实战经验 "
              ]
            },
            {
              "description": "理解并使用JWT进行用户认证",
              "furtherExplanation": [
                "熟练实施 JWT 以实现安全的用户认证 "
              ]
            }
          ]
        },
        {
          "title": "项目RAKU：AI助手求职系统",
          "startDate": "2023-03",
          "endDate": "2023-11",
          "projectLocation": "香港",
          "description": "开发了一个集成 OpenAI API 的 AI 助手求职系统，允许用户通过自然语言进行工作查询 ",
          "features": [
            {
              "description": "开发了网站抓取功能，从香港的各种知名在线就业公司抓取和提取数据",
              "furtherExplanation": [
                "设计并实现了网站抓取功能，有效从香港主要在线招聘平台提取数据 "
              ]
            },
            {
              "description": "使用Python (FastAPI)作为项目框架的后端编程语言",
              "furtherExplanation": [
                "采用 Python（FastAPI）构建项目后端，确保系统高效运行 "
              ]
            },
            {
              "description": "利用Project Gaia的经验设计了一个稳固的数据结构，并使用MongoDB构建数据库",
              "furtherExplanation": [
                "借鉴 Project GAIA 的经验，设计了稳固的数据结构，并采用 MongoDB 构建数据库 "
              ]
            }
          ]
        },
        {
          "title": "项目GAIA：设计数据结构",
          "startDate": "2022-11",
          "endDate": "2023-11",
          "projectLocation": "香港",
          "description": "本项目致力于设计高效的 NoSQL 数据结构，以优化地理静态数据的存储和检索 ",
          "features": [
            {
              "description": "使用MongoDB设计数据库，以存储非结构化地理静态数据，实现高效的数据存储和检索",
              "furtherExplanation": [
                "利用 MongoDB 构建数据库方案，实现非结构化地理静态数据的高效存储与检索 "
              ]
            },
            {
              "description": "学习了使用哈希进行数据内容比较和历史管理",
              "furtherExplanation": [
                "掌握了哈希技术在数据内容比较及历史管理中的应用 "
              ]
            },
            {
              "description": "学习了如何使用Python重新格式化和重构原始数据（字典/JSON）",
              "furtherExplanation": [
                "精通使用 Python 对原始数据（字典/JSON）进行重新格式化和结构重构 "
              ]
            }
          ]
        }
      ]
    },
    {
      "sessionName": "workExperience",
      "headerName": "工作 / 实习经验",
      "experiences": [
        {
          "companyName": "WeGreen AI",
          "companyURL": "wegreen.ltd",
          "jobTitle": "COT",
          "jobDescription": "通过人工智能解决方案革新废物管理流程，提升回收效率，打造更智能、更快速、更高效的可持续未来 ",
          "features": [
            {
              "description": "从HKBU和HKSTP获得了$130K的资金，以推进产品开发并支持可持续增长 ",
              "furtherExplanation": [
                
              ]
            },
            {
              "description": "领导了智能垃圾桶技术的开发，优化废物管理解决方案 ",
              "furtherExplanation": [
                
              ]
            },
            {
              "description": "培养了一种促进创新并赋予团队卓越能力的协作文化 ",
              "furtherExplanation": [
                
              ]
            }
          ],
          "location": "香港",
          "startDate": "2024-11",
          "endDate": "now",
          "relatedSkills": [
            "产品开发",
            "微控制器",
            "技术产品开发",
            "网络",
            "机器学习",
            "计算机视觉"
          ]
        },
        {
          "companyName": "东莞宏声电子科技有限公司",
          "companyURL": "honsennaudio.com/",
          "jobTitle": "技术研究员",
          "jobDescription": "协助微控制器功能的开发，并为产品研发提供有力支持 ",
          "features": [],
          "location": "东莞",
          "startDate": "2024-07",
          "endDate": "2024-09",
          "relatedSkills": [
            "产品开发",
            "微控制器",
            "技术产品开发"
          ]
        },
        {
          "companyName": "GoSmart Everywhere Limited",
          "companyURL": "gosmart-everywhere-limited.webflow.io",
          "jobTitle": "网页开发实习生",
          "jobDescription": "与资深软件工程师紧密协作，积极推动并参与项目开发，持续提升技术能力 ",
          "features": [],
          "location": "香港",
          "startDate": "2023-08",
          "endDate": "2024-09",
          "relatedSkills": [
            "TypeScript",
            "React.js",
            "Python",
            "网络",
            "网络建设",
            "网页开发",
            "工程"
          ]
        }
      ]
    },
    {
      "sessionName": "extraSkill",
      "headerName": "语言和技能",
      "points": [
        "普通话：母语水平",
        "粤语：具备基础听说能力",
        "英语：流利听说"
      ]
    },
    {
      "sessionName": "certification",
      "headerName": "认证",
      "certifications": [
        {
          "issuingOrganization": "NVIDIA",
          "organizationURL": "learn.nvidia.com",
          "CertificationList": [
            {
              "certificationName": "在 Jetson Nano 上开始使用 AI",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "fQ-LE71ATl6WD5MN4yhwtQ",
              "credentialURL": "https://learn.nvidia.com/certificates?id=fQ-LE71ATl6WD5MN4yhwtQ"
            },
            {
              "certificationName": "学习 OpenUSD：了解阶段、Prims 和属性",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "y__S8YX7QzONWbqN12vU-g",
              "credentialURL": "https://learn.nvidia.com/certificates?id=y__S8YX7QzONWbqN12vU-g"
            },
            {
              "certificationName": "学习 OpenUSD：遍历阶段",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "IeQUvQF6S-iNMTQCCPePOA",
              "credentialURL": "https://learn.nvidia.com/certificates?id=IeQUvQF6S-iNMTQCCPePOA"
            },
            {
              "certificationName": "学习 OpenUSD：理解模型种类",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "La6VqkPhSjejniAsjJSJAg",
              "credentialURL": "https://learn.nvidia.com/certificates?id=La6VqkPhSjejniAsjJSJAg"
            },
            {
              "certificationName": "学习 OpenUSD：使用属性",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "p7pwyGVYTUq7KiXa11NcRw",
              "credentialURL": "https://learn.nvidia.com/certificates?id=p7pwyGVYTUq7KiXa11NcRw"
            },
            {
              "certificationName": "使用 Jetson Nano 开发 AI 应用",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "iZTC_AWGSUG7D_Kfg13-Fg",
              "credentialURL": "https://learn.nvidia.com/certificates?id=iZTC_AWGSUG7D_Kfg13-Fg"
            },
            {
              "certificationName": "基于 Jetson Nano 构建视频 AI 的端侧应用",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "Poy-Xqz_QzudkNj5YwFJqA",
              "credentialURL": "https://learn.nvidia.com/certificates?id=Poy-Xqz_QzudkNj5YwFJqA"
            }
          ]
        },
        {
          "issuingOrganization": "思科",
          "organizationURL": "netacad.com",
          "CertificationList": [
            {
              "certificationName": "Python基础",
              "issuedDate": "2024-12",
              "expirationDate": "",
              "credentialID": "4f18dd7a-7133-48b2-8093-f3065cecaad0",
              "credentialURL": "https://www.credly.com/badges/4f18dd7a-7133-48b2-8093-f3065cecaad0/public_url"
            }
          ]
        }
      ]
    }
  ];
  